import stepMixin from '../../../mixins/stepMixin'
import checkTaskMixin from '../../../mixins/checkTaskMixin'
import FormCheck from '../../form/formCheck/FormCheck.vue'

export default {
    name: 'Verification',

    mixins: [stepMixin, checkTaskMixin],

    components: {
        FormCheck
    },

    data() {
        return {
            withoutSignatureConfirmed: null
        }
    },

    computed: {
        field() {
            return this.form.summary || {}
        },

        withoutSignatureHint() {
            return this.isDrivingServiceTask
                ? 'Der Fahrer hat die Unterschrift auf dem Übergabeprotokoll verweigert, die Übernahme erfolgt durch CAT-Mitarbeiter.'
                : 'Der Kunde hat die Unterschrift auf dem Übergabeprotokoll verweigert, die Übernahme erfolgt durch CAT-Mitarbeiter.'
        },

        signature() {
            return this.field.signature
        }
    }
}
