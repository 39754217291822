import EventBus from '../utils/eventBus'
import { mapGetters } from 'vuex'
import { lowerCaseFirst, searchTimeout } from '../utils/index'

export default {
    data() {
        return {
            searchTimer: null,
            vehicle: null
        }
    },

    props: {
        stepIsValid: {
            type: Boolean,
            required: true
        },

        view: {
            type: String,
            default: null
        },

        task: {
            type: String
        },

        taskTitle: {
            type: String
        }
    },

    mounted() {
        // Lets always scroll to top on route change
        // (important for summary)
        window.scrollTo(0, 0)

        this.validate()
        this.resetDialog()
    },

    computed: {
        ...mapGetters({
            form: 'form/form'
        }),

        componentName() {
            return lowerCaseFirst(this.$options.name)
        },

        field() {
            return this.form[this.componentName] || {}
        },

        isSummary() {
            return this.view === 'summary'
        },

        vehicleFound() {
            return (
                this.form?.vehicle &&
                Object.keys(this.form.vehicle).length > 0
            )
        },

        isLoading() {
            return (
                this.form?.runningSearchCount &&
                this.form.runningSearchCount > 0
            )
        }
    },

    methods: {
        validate() {}, // logic in parent

        triggerSearch() {
            this.searchTimer = searchTimeout(this.search, this.searchTimer)
        },

        isEmpty(field) {
            let isEmpty = false

            if (
                typeof field === 'undefined' ||
                field === null ||
                field === ''
            ) {
                isEmpty = true
            }

            return isEmpty
        },

        resetDialog() {
            EventBus.$emit('showDialog', {
                dialog: {}
            })
        }
    },

    watch: {
        form: {
            deep: true,

            handler() {
                this.validate()
            }
        }
    }
}
